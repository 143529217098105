import { Box } from '@chakra-ui/core'
import { rem } from 'design'
import { SmallButtonPrimary } from 'design/components/Button/Button'
import { ModalHeader } from 'design/components/Modal/Modal'
import { Body, LinkExternal } from 'design/components/Typography/Typography'
import React from 'react'
import AnimalIcons from '~/components/AnimalIcons/AnimalIcons'
import PanelLayout from '~/components/CancelFlowModals/PanelCancelLayout'
import { TEST_ID } from '~/constants/cypress'
import {
  GIFT_BOXES_PATH_SHOP,
  GIFT_BOXES_PATH_STOREFRONT,
  SHOP_SESSION_URL,
  STOREFRONT_DISCOUNT_CODE,
  STOREFRONT_SESSION_URL,
} from '~/data/constants'
import useOptimizelyFeature from '~/hooks/useOptimizelyFeature'
import { CARD_OFFER_GIFT_BOXES } from './StoppedSubscriptionPanel.constants'
import * as Styled from './StoppedSubscriptionPanel.styles'
import type { StoppedSubscriptionPanelProps } from './StoppedSubscriptionPanel.types'

const StoppedSubscription: React.FC<StoppedSubscriptionPanelProps> = ({
  panel,
}) => {
  // This stores the value of the coupon code to LS so that it can
  // be picked up when checking out of the OTB flow and automatically applied.
  const [isGiftLinkEnabled] = useOptimizelyFeature('ecom_810_remix_otb_rollout')
  CARD_OFFER_GIFT_BOXES.cta.href = isGiftLinkEnabled
    ? `${SHOP_SESSION_URL}?next=${encodeURIComponent(
        GIFT_BOXES_PATH_SHOP
      )}&dc=${STOREFRONT_DISCOUNT_CODE}`
    : `${STOREFRONT_SESSION_URL}?next=${encodeURIComponent(
        GIFT_BOXES_PATH_STOREFRONT
      )}&dc=${STOREFRONT_DISCOUNT_CODE}`
  const trackButtonClick = () => {
    window.localStorage.setItem(
      CARD_OFFER_GIFT_BOXES.otbCancelOffer,
      CARD_OFFER_GIFT_BOXES.coupon
    )
  }
  return (
    <PanelLayout>
      <ModalHeader
        data-cy={TEST_ID.CANCEL_FLOW_COMPLETED}
        pb={rem(8)}
        variant="bare"
      >
        {panel.headline}
      </ModalHeader>
      <Body>{panel.subhead}</Body>
      <Styled.Card>
        <Styled.CardHeadline>
          {CARD_OFFER_GIFT_BOXES.headline}
        </Styled.CardHeadline>
        <Box display={'flex'} justifyContent="center">
          <AnimalIcons
            aria-hidden
            color="black"
            iconName={CARD_OFFER_GIFT_BOXES.icon}
            size={'large'}
          />
        </Box>
        <Styled.CardBody>{CARD_OFFER_GIFT_BOXES.subhead}</Styled.CardBody>
        <Box pb={rem(10)} textAlign="center">
          <SmallButtonPrimary
            as={LinkExternal}
            href={CARD_OFFER_GIFT_BOXES.cta.href}
            maxWidth={{ base: rem(232), tablet: rem(250) }}
            onClick={trackButtonClick}
            py={rem(6)}
            width="100%"
          >
            {CARD_OFFER_GIFT_BOXES.cta.text}
          </SmallButtonPrimary>
        </Box>
      </Styled.Card>
    </PanelLayout>
  )
}

export default StoppedSubscription
