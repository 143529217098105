// This PANEL EXPORT is a placeholder until the data is provided from contentful.
// Once the Panel is receiving data from contentful this should be deleted and
// The fixture data persisted for Storybook Renderings.
import React from 'react'
import {
  GIFT_BOXES_PATH_STOREFRONT,
  STOREFRONT_DISCOUNT_CODE,
  STOREFRONT_SESSION_URL,
} from '~/data/constants'
import { PANEL } from './StoppedSubscriptionPanel.fixture'
import type { CardOffer } from './StoppedSubscriptionPanel.types'

const makeCardOffer = (url: string, promoCode: string): CardOffer => {
  return {
    headline: 'Here’s some good news!',
    subhead: (
      <>
        Love ButcherBox but don&rsquo;t want a subscription? Try out our gift
        boxes with 20% off using promo code <b>{promoCode}</b> !
      </>
    ),
    coupon: promoCode,
    otbCancelOffer: 'otb_cancel_offer',
    icon: 'outlineBeefChickenPork',
    cta: {
      href: url,
      text: 'Browse gift boxes',
    },
  }
}

// When sending customers to the Shopify Storefront, provide the discount code in the URL and
// direct them to the primary product listing page
const STOREFRONT_CANCELED_DISCOUNT_URL = `${STOREFRONT_SESSION_URL}?next=${encodeURIComponent(
  GIFT_BOXES_PATH_STOREFRONT
)}&dc=${STOREFRONT_DISCOUNT_CODE}`

export const CARD_OFFER_GIFT_BOXES = makeCardOffer(
  STOREFRONT_CANCELED_DISCOUNT_URL,
  STOREFRONT_DISCOUNT_CODE
)

export default PANEL
