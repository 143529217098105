import { styled, rem } from 'design'
import ModalCard from 'design/components/ModalCard/ModalCard'
import { Body } from 'design/components/Typography/Body'
import { H3 } from 'design/components/Typography/Headlines'

export const Card = styled(ModalCard)`
  min-height: ${rem(281)};
  margin-top: ${rem(32)};
  padding-bottom: ${rem(18)};
  ${(p) => p.theme.media.desktop} {
    padding-bottom: ${rem(25)};
  }
`
export const CardHeadline = styled(H3)`
  color: ${(p) => p.theme.colors.bb.spicedCrimson};
  font-size: ${rem(21)};
  padding-bottom: ${rem(10)};
  text-align: center;
`
export const CardBody = styled(Body)`
  margin: auto;
  max-width: ${rem(330)};
  padding-bottom: ${rem(20)};
  text-align: center;
`
